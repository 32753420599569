import moment from 'moment'
export function dateForm() {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    // return ['2024-06-12 22:40:56','2024-06-24 22:40:56']
    return [moment(startDate.valueOf()).format('YYYY-MM-DD HH:mm:ss'),moment(endDate.valueOf()).format('YYYY-MM-DD HH:mm:ss')]
}