import axios from "axios";
import baseURL from "./base";
import { Loading, Message } from 'element-ui';
import router from "@/router";
// 创建 axios 请求实例
const instance = axios.create({
    baseURL: baseURL.baseURL, // 基础请求地址
    timeout: 1000, // 请求超时设置
    // withCredentials: false, // 跨域请求是否需要携带 cookie
});

// 创建请求拦截
instance.interceptors.request.use(
    (config) => {
        // 如果开启 token 认证
        if (baseURL.useTokenAuthorization) {
            config.headers["Authorization"] = localStorage.getItem("token") || ''; // 请求头携带 token
        }
        // 设置请求头
        if (!config.headers["content-type"]) { // 如果没有设置请求头
            if (config.method === 'post') {
                config.headers["content-type"] = "application/x-www-form-urlencoded"; // post 请求
            } else {
                config.headers["content-type"] = "application/json"; // 默认类型
            }
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);


// 创建响应拦截
instance.interceptors.response.use(
    (res) => {
        // 处理自己的业务逻辑，比如判断 token 是否过期等等
        // 代码块
        return Promise.resolve(res.data);
    },
    (error) => {
        let message = "";
        if (error.response.data.code == 401 || error.response.data.msg == 'token失效') {
            router.replace('/login')
        }
        Message.error(`请求错误: ${error.response.data.msg}`)
        return Promise.reject(message);
    }
);
export default instance;