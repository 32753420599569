<template>
  <div class="card">
    <i class="el-icon-arrow-left" v-if="!show" @click="show = true">返回</i>
    <div class="top" :style="!show ? 'justify-content: flex-end;' : ''">
      <div class="titleNum" v-if="show">今日总量:{{ num }}</div>
      <div class="title" v-if="!show">{{ dateDetail?.name }}</div>
      <div class="date">
        <el-date-picker
          :append-to-body="false"
          v-model="timer"
          :class="show?'':'clear'"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          align="right"
          :default-time="['00:00:00', '23:59:59']"
          ref="datePick"
          :clearable ='show'
          @change="changeTime"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="content" ref="content"></div>
    <contentIndex
      v-if="show"
      @jump="jump"
      @setNum="setNum"
      :timer="timer"
      ref="contentIndex"
    />
    <detail
      ref="contentIndex"
      :total="total"
      :tableData="tableData"
      @handleCurrentChange="handleCurrentChange"
      v-else
    />
    <div class="bottom">所选日期:{{ timer[0] }}到{{ timer[1] }}</div>
  </div>
</template>

<script>
import detail from "@/components/content/detail.vue";
import contentIndex from "@/components/content/index.vue";
import { homeDetailsApi } from "@/api/content";
import { dateForm } from "@/utils/index";
export default {
  data() {
    // 设置默认选中时间当天0:00:00~23:59：59
    return {
      show: true,
      num: "",
      timer: dateForm(),
      dateDetail: {},
      page: 1,
      tableData: [],
      size: 10,
      total: 0,
    };
  },
  components: {
    detail,
    contentIndex,
  },
  watch: {
    timer(newValue) {
      if (!newValue) {
        this.changeTime()
      }
    },
  },
  methods: {
    jump(data) {
      this.dateDetail = data;
      this.homeDetails(data.id);
    },
    async homeDetails(id) {
      let data = {
        "time[0]": this.timer[0],
        "time[1]": this.timer[1],
        id: id,
        page: this.page,
        size: this.size,
      };
      try {
        const res = await homeDetailsApi(data);
        console.log(res);
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.show = false;
      } catch (error) {}
    },
    handleCurrentChange(data) {
      this.page = data;
      this.homeDetails(this.dateDetail?.id);
    },
    setNum(data) {
      this.num = data;
    },
    //选择时间之后的时间
    changeTime() {
      let data = {};
      console.log(this.timer, 11111111);
      if (this.timer) {
        data = {
          "time[0]": this.timer[0],
          "time[1]": this.timer[1],
        };
      } else {
        this.timer = [];
      }
      if (this.show) {
        this.$refs.contentIndex.homeList(data);
      } else {
        this.homeDetails(this.dateDetail?.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  display: flex;
  position: relative;
  flex-direction: column;
  i {
    left: 0;
    z-index: 999;
    font-size: 14px;
    color: #999;
    top: 10px;
    position: absolute;
    cursor: pointer;
  }
}
.top {
  display: flex;
  padding-top: 40px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .title {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 20px;
  }
}
.bottom {
  text-align: center;
  width: 100%;
  height: 40px;
}

::v-deep .clear .el-button--text{
    display: none;
}
</style>