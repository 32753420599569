<template>
  <el-container>
    <el-header>
      <headers></headers>
    </el-header>
    <el-container>
      <el-aside :width="$store.state.isCollapse ? '50px' :'200px'"><menus></menus></el-aside>
      <el-main>
        <tags></tags>
        <div class="box-card">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import menus from "@/layout/components/v-menu";
import headers from "@/layout/components/v-header";
import tags from "@/layout/components/v-tag";

export default {
  components: {
    menus,
    headers,
    tags,
  },
};
</script>

<style lang="less" scope>
.el-container {
  height: 100vh;
}

// .el-main{
//   background: #968b8b;
// }

.el-main {
  padding: 0 !important;
  overflow:auto !important;
}
.el-aside{
  box-sizing: border-box;

}
.box-card{
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  height: calc(100vh - 108px);
  background-color: #f2f3f5;
}
</style>