<template>
  <div class="tags">
    <div class="iconfont ls-icon-shouye-on"></div>
    <el-tag
      :key="tag.name"
      color="#fff"
      closable
      :class="$route.name === tag.name ? 'active' :''"
      v-for="(tag, index) in tags"
      @close="handleClose(tag, index)"
      size="small"
      @click="changeMenu(tag)"
    >
      {{ tag.label }}
    </el-tag>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "CommonTags",
  computed: {
    ...mapState({
      tags: (state) => state.tabsList,
    }),
  },
  methods: {
    ...mapMutations({
      close: "closeTab",
    }),
    changeMenu(tag) {
      //在走一遍vuex中 selectMenu方法，将tag也是内容传递进去
      this.$store.commit("selectMenu", tag);
      if (tag.name === this.$route.name) return;
      this.$router.push({ name: tag.name });
    },
    handleClose(tag, index) {
      let length = this.tags.length - 1;
      console.log(this.tags.length);
      if (this.tags.length <= 1) return;
      this.close(tag);
      if (tag.name !== this.$route.name) {
        return;
      }
      // 判断vuex中的tabsList下标，如果跟长度相等，跳转后最一个页面
      if (index === length) {
        this.$router.push({ name: this.tags[index - 1].name });
      } else {
        this.$router.push({ name: this.tags[index].name });
      }
    },
  },
};
</script>

<style lang='less' scoped>
.tags {
  display: flex;
  align-items: center;
  div {
    margin: 0px 10px;
    color: #8b92b3;
  }
}
.el-tag {
  cursor: pointer;
   
  color: #8b92b3;
  margin-right: 8px;
  border: 1px solid #e7eaf1;
}
::v-deep .el-tag__close {
  color: #8b92b3 !important;
}
.active{
  color: #fff;
  background-color: #0066FF !important;
}
</style>