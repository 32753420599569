<template>
  <div>
    <template v-if="!item.children || item.children.length === 0">
      <el-menu-item :index="item.path" @click="clickMenu(item)">
        <i
          style="margin-right: 5px"
          :class="'iconfont ls-icon-' + item.icon"
        ></i>
        <span slot="title">{{ item.label }}</span>
      </el-menu-item>
    </template>
    <el-submenu :index="item.path" v-else>
      <template slot="title">
        <i
          style="margin-right: 5px"
          :class="'iconfont ls-icon-' + item.icon"
        ></i>
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item
        :index="subItem.path"
        v-for="(subItem, subIndex) in item.children"
        :key="subIndex"
        @click="clickMenu(subItem)"
      >
        {{ subItem.label }}
      </el-menu-item>
    </el-submenu>
  </div>
</template>

<script>
export default {
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clickMenu(item) {
      this.$store.commit("selectMenu", item);
    },
  },
};
</script>

<style>
.el-menu--collapse .el-submenu__title span {
  display: none;
}

.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
</style>