<template>
  <div class="nav">
    <div class="nav_left">
      <div>
        <!-- <img src="@/assets/top.png" alt="" /> -->
        <h3>后台管理系统</h3>
      </div>
      <div class="nav_title" @click="isCollapsechange">
        <span
          class="iconfont ls-icon-shouqi"
          v-if="!$store.state?.isCollapse"
        ></span>
        <span class="iconfont ls-icon-zhankai" v-else></span>
        <div>{{ $route.meta.bread ? $route.meta.bread[0].name : "" }}</div>
      </div>
    </div>
    <div class="nav_right">
      <!-- <img src="@/assets/top.png" alt="" /> -->
      <img :src="$store.state.userInfoData?.img_url" alt="" />
      <el-dropdown>
        <!-- <img :src="$store.state.userInfoData?.img_url" alt=""> -->
        <span class="el-dropdown-link">
          {{ $store.state.userInfoData?.name
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="LogOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
  },
  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    isCollapsechange() {
      this.isCollapse = !this.isCollapse;
      this.$store.commit("changeisCollapse", this.isCollapse);
    },
    LogOut() {
      this.$router.push("/login");
      localStorage.removeItem("token");
    },
  },
};
</script>

<style lang="less" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #8b92b3;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.nav {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  .nav_left {
    display: flex;
    & > div:nth-child(1) {
      width: 180px;
      img {
        margin-right: 8px;
      }
    }
    div {
      display: flex;
      align-items: center;
    }
    .nav_title {
      color: #8b92b3;
      cursor: pointer;
      span {
        margin-right: 5px;
      }
    }
  }
  .nav_right {
    display: flex;
    img {
      width: 16px;
      margin-right: 8px;

      height: 15px;
    }
  }
}
</style>