import request from '@/request/http'

// 获取列表
// export const homecustomerApi = (params) => request.get(`/admin/home/customer?time[0]=${params.time[0]}&time[1]=${params.time[1]}`)
// // 详情
// export const homeDetailsApi = (params) => request.get(`/admin/home/details?time[0]=${params.time[0]}&time[1]=${params.time[1]}&id=${params.id}&page=${params.page}&size=${params.size}`)



// 获取列表
export const homecustomerApi = (params) => request.get(`/admin/home/customer`,{params})
// 详情
export const homeDetailsApi = (params) => request.get(`/admin/home/details`,{params})