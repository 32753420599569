<template>
  <div id="app">
       <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
/* #app{
  max-width: 1728px;
} */
</style>
