<template>
  <div class="tables">
    <el-table
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#F1F1F5' }"
      :cell-style="{ padding: '4px 0' }"
    >
      <el-table-column prop="nickname" label="姓名"></el-table-column>
      <el-table-column prop="mobile" label="手机号"></el-table-column>
      <el-table-column prop="sex" label="性别"></el-table-column>
      <el-table-column prop="age" label="年龄"></el-table-column>
      <el-table-column prop="education" label="学历"></el-table-column>
      <el-table-column prop="create_time" label="创建时间"></el-table-column>
      <el-table-column label="渠道来源">
        <template slot-scope="scope">
          {{ scope.row?.getSource?.name }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :page-size="pages.size"
      layout="total, prev, pager, next"
      :total="total"
      background
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: ["tableData", "total"],
  data() {
    return {
      pages: {
        current: 1,
        size: 10,
      },
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pages.current = val;
      console.log(this.pages);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`, "11111");
      this.pages.current = val;
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>
<style lang="less" scoped>
.tables {
  padding-top: 40px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
::v-deep .el-pagination {
  margin: 20px 0;
  text-align: center !important;
}
</style>