<template>
  <el-menu
    :collapse="$store.state.isCollapse"
    unique-opened
    :collapse-transition="false"
    :default-active="$route.path"
    class="el-menu-vertical-demo"
    text-color="#494F6D"
    active-text-color="#0066FF"
    router
  >
    <v-menu-item
      v-for="item in menu"
      :key="item.name"
      :item="item"
    ></v-menu-item>
  </el-menu>
</template>

<script>
import navmenu from "./navmenu";
import vMenuItem from "./v-menuItem.vue";

export default {
  //计算属性

  components: {
    vMenuItem,
  },

  data() {
    return {
      //获取菜单栏数据并赋值给menu
      menu: navmenu,
      activeOpenEds: "",
    };
  },
};
</script>

<style>
.el-menu-item.is-active{
  background-color: #ecf5ff !important;
}
</style>