module.exports = [
    {
        path: '/homepage',
        name: 'homepage',
        label: '首页',
        icon: 'shouye-on'
    },
    {
        path: '/content',
        name: 'Content',
        label: '数据',
        icon: 'tongdaoguanli-off',
    },
    {
        path: '/taskEditing',
        name: 'TaskEditing',
        label: '任务编辑',
        icon: 'yingyongguanli-off'
    },
  
    {
        path: '/management',
        name: 'Management',
        label: '管理',
        icon: 'yongliangguanli-off'
    },
    {
        path: '/miniProgram',
        name: 'MiniProgram',
        label: '小程序',
        icon: 'yongliangguanli-off'
    }
]