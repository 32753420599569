import Vue from 'vue'
import VueRouter from 'vue-router'
import login from "@/views/login";

import layout from "@/layout";
import Content from "@/views/content";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'layout',
    component: layout,
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        name: 'homepage',
        component: () => import('../views/homepage/index.vue'),
        meta: {
          bread: [{ name: '首页' }],
          isLogin: true
        }
      },
      {
        path: '/content',
        name: 'Content',
        component: Content,
        meta: {
          bread: [{ name: '数据' }],
          isLogin: true
        },
        children: [

        ]
      },
      {
        path: '/taskEditing',
        name: 'TaskEditing',
        component: () => import('../views/taskEditing/index.vue'),
        meta: {
          bread: [{ name: '任务编辑' }],
          isLogin: true
        }
      },
      {
        path: '/management',
        name: 'Management',
        component: () => import('../views/management/index.vue'),
        meta: {
          bread: [{ name: '管理' }],
          isLogin: true
        }
      }
      ,
      {
        path: '/miniProgram',
        name: 'MiniProgram',
        component: () => import('../views/miniProgram/index.vue'),
        meta: {
          bread: [{ name: '管理' }],
          isLogin: true
        }
      },
      {
        path: '/Details',
        name: 'Details',
        component: () => import('../views/Details/index.vue'),
        meta: {
          bread: [{ name: '详情' }],
          isLogin: true
        }
      }
    ]
  }
  ,
  {
    path: '*',
    component: () => import('../views/NotFound/index.vue'),
  }
]
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    let token = localStorage.getItem('token');
    if (!token && to.meta.isLogin) {
      next('/login');
    } else {
      next();
    }
  }
});


export default router