import Vue from 'vue'
import Vuex from 'vuex'
//持久化存储插件 import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    // 定义的数据，tab标签，这是初始化跳转页面进去就存在的，不想要可以设置
    tabsList: [
      {
        path: '/homepage',
        name: 'homepage',
        label: '首页',
        icon: 'shouye-on'
      }
    ],
    isCollapse: false,
    userInfoData: JSON.parse(localStorage.getItem('userInfoData')) || {},
    token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
  },
  mutations: {
    //左侧菜单栏点击后将数据传递进来将数据添加到tabsList中 添加tab标签
    selectMenu(state, val) {
      let result = state.tabsList.findIndex(item => item.name === val.name);
      result === -1 ? state.tabsList.push(val) : ''
    },
    // tab标签删除
    closeTab(state, val) {
      let result = state.tabsList.findIndex(item => item.name === val.name);
      state.tabsList.splice(result, 1);
    },
    changeisCollapse(state, val) {
      state.isCollapse = val
    },
    saveUserInfoData(state, val) {
      state.userInfoData = val
      localStorage.setItem('userInfoData', JSON.stringify(val))
    }
  },
  actions: {

  }
})