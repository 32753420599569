<template>
  <div class="login-box">
    <div class="login-container">
      <div class="container-left"></div>
      <div class="container-right">
        <div class="right_box">
          <h2 class="right-title">登录</h2>
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            autocomplete="on"
            label-position="left"
          >
            <el-form-item prop="username">
              <el-input
                ref="username"
                v-model="loginForm.username"
                placeholder="请输入用户名称"
                name="username"
                type="text"
                autocomplete="off"
                clearable
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                ref="password"
                v-model="loginForm.password"
                type="password"
                placeholder="请输入登录密码"
                name="password"
                clearable
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item prop="code" class="code">
              <el-input
                ref="code"
                v-model="loginForm.code"
                type="text"
                placeholder="请输入验证码"
                name="code"
                clearable
                autocomplete="off"
                @keyup.enter.native="handleLogin"
              />
              <img :src="baseUrl" alt="" @click="verificationCode" />
            </el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              @click.native.prevent="handleLogin"
            >
              登录
            </el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { verificationCodeApi, loginApi, userInfoApi } from "@/api/login";
export default {
  name: "Login",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入登录密码！"));
      } else {
        callback();
      }
    };

    return {
      baseUrl: "",
      loginForm: {
        username: "",
        password: "",
        code: "",
        checkList: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "change", message: "请输入用户名称！" },
        ],
        password: [
          { required: true, trigger: "change", validator: validatePassword },
        ],
        code: [
          { required: true, trigger: "change", message: "请输入验证码！" },
        ],
      },
      loading: false,
      editPassDialogVisible: false,
      userId: "",
    };
  },

  created() {
    this.verificationCode();
  },
  methods: {
    async verificationCode() {
      const res = await verificationCodeApi();
      console.log(res);
      this.baseUrl = res.data;
    },

    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (!valid) return false;
        let data = { ...this.loginForm };
        this.login(data);
      });
    },
    async login(data) {
      try {
        const res = await loginApi(data);
        localStorage.setItem("token", res.data.token);
        this.userInfo();
        this.$message.success("登录成功");
        this.$router.push("/homepage");
      } catch (error) {
        console.log(error);
        this.loginForm.code = "";
        this.verificationCode();
      }
    },
    async userInfo() {
      const res = await userInfoApi();
      this.$store.commit("saveUserInfoData", res.data);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.login-box {
  min-height: 600px;
  height: 100vh;
  background: linear-gradient(
    45deg,
    #d6def4 0%,
    #eceef5 49%,
    #f7ffff 85%,
    #e7eeff 100%
  );
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-container {
    width: 50%;
    min-width: 650px;
    height: 50%;
    display: flex;
    background-color: #fff;
    border-radius: 10%;
    .container-right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5%;
      text-align: center;
      background-color: #fff;
      .el-form-item {
        position: relative;
      }
      .right_box {
        width: 60%;
        ::v-deep .el-input__inner {
          height: 50px !important;
          font-size: 14px;
        }
        .right-title {
          font-size: 30px;
          font-family: AlibabaPuHuiTiM;
          color: #131729;
          line-height: 42px;
          text-shadow: 0px 2px 19px rgba(12, 27, 95, 0.1);
          margin-bottom: 20px;
        }

        ::v-deep .el-form-item {
          text-align: left;
        }
        .el-button {
          width: 100%;
          height: 50px;
          font-size: 16px;
        }
      }
    }
  }
}
.code {
  position: relative;
  img {
    position: absolute;
    top: 0;
    cursor: pointer;
    right: 0;
    width: 100px;
    height: 98%;
    z-index: 99;
  }
}
</style>
