<template>
  <div>
    <div class="content" ref="content"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { homecustomerApi } from "@/api/content";
export default {
  props: ["timer"],
  data() {
    return {
      num: 100,
      phone: "",
      date: "",
      chartBar: null,
      dataBar: [],
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "60%",
            data: [10, 52, 200, 334, 390, 330, 220],
          },
        ],
      },
    };
  },
  mounted() {
    this.homeList({ "time[0]": this.timer[0], "time[1]": this.timer[1] });
    window.addEventListener("resize", this.handlerResize);
    setTimeout(() => {
      this.chartBar.getZr().on("click", (params) => {
        if (this.timer.length == 0) return this.$message.warning("请选择日期");
        const pointInPixel = [params.offsetX, params.offsetY];
        if (this.chartBar.containPixel("grid", pointInPixel)) {
          let xIndex = this.chartBar.convertFromPixel({ seriesIndex: 0 }, [
            params.offsetX,
            params.offsetY,
          ])[0];
          console.log(xIndex);
          this.jump(this.dataBar.list[xIndex]);
        }
      });
    }, 500);
  },
  methods: {
    async homeList(data) {
      const res = await homecustomerApi(data);
      this.dataBar = res.data;
      this.option.xAxis[0].data = res.data.list.map((item) => item.name);
      this.option.series[0].data = res.data.list.map((item) => item.count);
      this.chartBar = echarts.init(this.$refs.content);
      this.$emit("setNum", res.data.total_count);
      this.chartBar.setOption(this.option);
    },
    jump(data) {
      this.$emit("jump", data);
    },
    // 处理网页滚动缩放
    handlerResize() {
      if (this.chartBar) {
        console.log(11);
        this.chartBar.resize();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  height: calc(100vh - 300px);
}
</style>